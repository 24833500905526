import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from "../../assets/img/logo-nanny.png";
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { clearSignupData, signupUser } from '../../store/apiSlice/Signupnannyslice';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-input-2';
import { ClipLoader } from "react-spinners";

const Signupnanny = () => {

    const dispatch = useDispatch();
    const data = useSelector((state) => state.rootReducer.signUp.data);
    const isLoading = useSelector((state) => state.rootReducer.signUp.loading);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [mobileNumber, setmobileNumber] = useState('');
    const [experienceOfYears, setexperienceOfYears] = useState('');
    const [postalCode, setpostalCode] = useState('');
    const [address, setaddress] = useState('');
    const [country, setCountry] = useState('+91');

    const handleOnChange = (value, country) => {
        console.log(value)
        //setMobileNumber(va);
        setCountry(value);
    };

    const handleSignup = () => {
        const payload = {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            countryCode: country,
            mobileNumber: mobileNumber,
            referralCode: "",
            dateOfBirth: "",
            experienceOfYears: experienceOfYears,
            country: "sds",
            postalCode: postalCode,
            address: address,
            latitude: "23.23232",
            longitude: "45.34343",
            type: 1
        }

        try {
            console.log("payload signup user ===>", payload)
            dispatch(signupUser(payload));
            // Handle success, e.g., navigate to another screen
        } catch (error) {
            // Handle error, e.g., display an error message
            console.error('signup failed:', error);
        }
    };


    const navigate = useNavigate();

    const navigateToAnotherScreen = () => {
        navigate('/otp');
    }

    useEffect(() => {
        console.log("data signup nanny ===>", data)
        if (data != null && data.status === 1) {
            navigateToAnotherScreen();
        }
        else if (data != null) {
            alert(data.message)
            dispatch(clearSignupData())
        }
    }, [data])


    const [disabled, setDisabled] = useState(false)




    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="container py-5">

                    <a href="#" className="custom-logo d-block login-logo mx-auto">
                        <img className="mw-100" src={logo} alt="logo" />
                    </a>

                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 offset-sm-1 col-sm-10">
                            <div className="login-container">
                                <form className="login-form" appearance="outline">
                                    <h3>Welcome to Nanny</h3>
                                    <p>Please Signup </p>

                                    <div className='input-group input_width'>
                                        <input type="text" placeholder="FirstName" value={firstName} onChange={(e) => setfirstName(e.target.value)} style={{ width: "49%", }} />
                                        <input type="text" placeholder="LastName" value={lastName} onChange={(e) => setlastName(e.target.value)} style={{ width: "49%" }} />
                                    </div>

                                    <div className='btn_flex mb-3'>
                                        <PhoneInput
                                            inputClass={"inputt-ph"}
                                            containerStyle={{}}
                                            searchClass="search-class"
                                            disableSearchIcon={false}
                                            enableTerritories
                                            countryCodeEditable={false}
                                            defaultCountry="IN"
                                            placeholder='Phone Number'
                                            buttonStyle={{ width: '47px' }}
                                            dropdownStyle={{ height: '150px' }}
                                            enableSearch={true}
                                            value={country}
                                            onChange={handleOnChange}
                                            inputProps={{
                                                readOnly: true, // This makes the input read-only
                                            }}
                                            enableAreaCodeStretch
                                        />
                                        <input type='number' className='mobilenumber' value={mobileNumber} onChange={(e) => setmobileNumber(e.target.value)} />
                                    </div>

                                    <div className='input-group'>
                                        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>

                                    <div className='input-group'>
                                        <input type="text" placeholder="Address" value={address} onChange={(e) => setaddress(e.target.value)} />
                                    </div>

                                    <div className='input-group'>
                                        <input type="number" placeholder="Postal Code" value={postalCode} onChange={(e) => setpostalCode(e.target.value)} />
                                    </div>

                                    <div className='input-group'>
                                        <input type="number" placeholder="Experience of years" value={experienceOfYears} onChange={(e) => setexperienceOfYears(e.target.value)} />
                                    </div>

                                    <div className='input-group'>
                                        <input type="text" placeholder="Education" />
                                    </div>

                                    <div className="input-group">
                                        <input
                                            type='password'
                                            id="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>

                                    <Button type='button' onClick={() => handleSignup()}>
                                        {isLoading ? (
                                            <ClipLoader color="#fff" />
                                        ) : (
                                            "Register"
                                        )}
                                    </Button>

                                    <div className='bottom-text'>
                                        <div className="mt-3 bottom_link">Im a new user.
                                            <div className='flex_link'>
                                                <p className='link_color' onClick={() => { scrollToTop(); navigate('/signupnanny') }} > {" "}REGISTER AS NANNY</p>
                                                <span className='mx-1'> / </span>
                                                <p className='link_color' onClick={() => { scrollToTop(); navigate('/signupfamily') }}>REGISTER AS FAMILY</p>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Signupnanny
