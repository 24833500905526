import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { categories } from '../../store/apiSlice/categoriesSlice';

const Services = () => {

    const dispatch = useDispatch();
    const categoriesData = useSelector((state) => state.rootReducer.categoriesReducer.data);
    const [nannycatData, setNannycatData] = useState(null);

    useEffect(() => {
        dispatch(categories());
    }, []);

    useEffect(() => {
        console.log("categoriesData ===>", categoriesData)
        if (categoriesData != null && categoriesData.status === 1) {
            setNannycatData(categoriesData.data);
        }
    }, [categoriesData]);


    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (

        <>
            <div className=" nannycategories">
                <div className='banner_categories banner_service'>
                    <div className='heading_categories container'>
                        <h1>Services</h1>
                    </div>
                </div>
            </div>
            <div className="sections_padding mt-3">
                <div className="container">
                    <h1 className="text-center mb-4 pb-3">
                        <span className="position-relative content_offer">What do we offer exactly?</span>
                    </h1>
                    <p className="text-center">During all of our services we aim to provide the best possible service to our customers</p>

                    {nannycatData != null ?
                        nannycatData.map((item, index) =>
                            <div>
                                {index % 2 == 0 ?
                                    <div className="row mt-0 mt-md-5 mt-sm-0 row_padding">
                                        <div className="col-lg-6 o p-0">
                                            <img className="w-100 my-lg-5 my-md-0 service_holiday_img" src={"https://nanny-media.s3.eu-north-1.amazonaws.com/" + item.image} alt="image" />
                                        </div>
                                        <div className="col-lg-6 service_text secondry_bg">
                                            <div className="hotel_book">
                                                <h2 className="mb-4 fw-700 nanny_card_header_name">{item.name}</h2>
                                                <p>{item.description}​</p>
                                                <Link to="/login" onClick={scrollToTop} className="btn btn-primary d-inline-block mt-4 all_btns px-4 py-2 position-relative">Book Now</Link>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row mt-5 flex-row-reverse row_padding">
                                        <dive className="col-lg-6 p-0">
                                            <img className="w-100 my-0 my-lg-5 my-md-0" src={"https://nanny-media.s3.eu-north-1.amazonaws.com/" + item.image} alt="image" />
                                        </dive>
                                        <div className="col-lg-6 service_text secondry_bg ">
                                            <div className="hotel_book">
                                                <h2 className="mb-4 fw-700">{item.name}</h2>
                                                <p>{item.description}​</p>
                                                <Link to="/login" onClick={scrollToTop} className="btn btn-primary d-inline-block mt-4 all_btns px-4 py-2 position-relative">Book Now</Link>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                        ) : (
                            ""
                        )}
                </div>
            </div >
            <div className="wny_us sections_padding">
                <div className="container">
                    <h1 className="pb-3 mb-4 text-center">
                        <span className="position-relative content_offer">Extra services
                        </span>
                    </h1>
                    <p className="text-center">Besides regular babysitting, The Nanny Line offers a special range of nanny-services. Meet with the new concept of babysitting!</p>
                    <div className="row mt-3 mt-md-5 mt-sm-3">
                        <div className="col-sm-6 col-lg-3 mb-3">
                            <div className="service_text secondry_bg pb-4 pb-sm-5 px-3 px-sm-4 h-100">
                                <div className="choose_icon mb-5 d-inline-block">
                                    <i className="fas fa-chalkboard-teacher"></i>
                                </div>
                                <h3 className="mb-4 fw-700">Tutoring nanny</h3>
                                <p>English is definitely the most spoken language in the world, The Nanny Line gives your children the opportunity to learn English, the language of business and one of the most widely spoken languages in the world. English Tutor Nannies create fun and playful materials and games for your child so they enjoy their learning time. Our tutor nannies are qualified English teachers, certified with TEFL- Teach English in Foreign Language. The price for a tutor nanny is €12.50 per hour.</p>
                                <Link to="/login" onClick={scrollToTop} className="btn btn-primary d-inline-block mt-4 all_btns px-4 py-2 position-relative">Boook Now</Link>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3 why_2">
                            <div className="service_text secondry_bg pb-4 pb-sm-5 px-3 px-sm-4 h-100">
                                <div className="choose_icon mb-5 d-inline-block">
                                    <i className="fas fa-share"></i>
                                </div>
                                <h3 className="mb-4 fw-700">Nanny sharing</h3>
                                <p>We offer Nanny Sharing with this in mind. By sharing a nanny, you and your friends can take a coffee break, catch-up or learn Spanish together. Our nannies can babysit yours and 2 friend’s children for €7.50 per hour per child (if you’re a member), for a minimum of 2 hours. The cost per hour for non-members is 8.50 per child per hour.</p>
                                <Link to="/login" onClick={scrollToTop} className="btn btn-primary d-inline-block mt-4 all_btns px-4 py-2 position-relative">Boook Now</Link>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3 why_3">
                            <div className="service_text secondry_bg pb-4 pb-sm-5 px-3 px-sm-4  h-100">
                                <div className="choose_icon mb-5 d-inline-block">
                                    <i className="fas fa-hourglass-start"></i>
                                </div>
                                <h3 className="mb-4 fw-700">Part-time & Long-term bookings</h3>
                                <p>Would you like to have somebody who is taking care of your children during your afternoon routine? Looking for somebody to pick up the guys in the school? We provide a wide range of part-time and long-term nannies.</p>
                                <Link to="/login" onClick={scrollToTop} className="btn btn-primary d-inline-block mt-4 all_btns px-4 py-2 position-relative">Boook Now</Link>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3 why_4">
                            <div className="service_text secondry_bg pb-4 pb-sm-5 px-3 px-sm-4 h-100">
                                <div className="choose_icon mb-5 d-inline-block">
                                    <i className="fas fa-sack-dollar"></i>
                                </div>
                                <h3 className="mb-4 fw-700">Specially priced nanny</h3>
                                <p>This booking is exactly like booking The Nanny Line nannies the only difference is that this is their first booking with our agency and we require one thing from you…your review/feedback after the booking!</p>
                                <Link to="/login" onClick={scrollToTop} className="btn btn-primary d-inline-block mt-4 all_btns px-4 py-2 position-relative">Boook Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Services