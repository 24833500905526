import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import RedeemIcon from "@mui/icons-material/Redeem";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import nanny from "../../assets/img/event_main.jpg";
import { Button, Form, Modal, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import profile_icon from "../../assets/img/profile_icon.png";
import { fireEvent } from "@testing-library/react";
import {
  clearBookingData,
  fetchbooknAsync,
} from "../../store/apiSlice/BooknannySlice";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../stripe/CheckoutForm";

//import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

//const stripePromise = loadStripe('your_publishable_key');

//import { loadStripe } from '@stripe/stripe-js';
//import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
//const stripePromise = loadStripe('pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3');

const Bookdetails = () => {
  const location = useLocation();
  const {
    address,
    date,
    time,
    counter,
    divs,
    latitude,
    longitude,
    categoryId,
    profileImage,
    price,
    firstName,
    lastName,
    nannyId,
  } = location.state;

  const navigate = useNavigate();

  const bookingData = useSelector(
    (state) => state.rootReducer.bookNannyData.data
  );
  const [show, setShow] = useState(false);
  const [discount, setDiscount] = useState(0);

  const handleCloses = () => setShow(false);
  const handleShows = () => setShow(true);

  const dispatch = useDispatch();
  const handlebook = () => {
    console.log("ON Book Click ===>");
    const payload = {
      nannyId: nannyId,
      categoryId: categoryId,
      date: date,
      time: time,
      numberOfHours: 2,
      childrenDetails: divs,
      price: price,
      discount: discount,
      address: address,
      latitude: latitude,
      longitude: longitude,
      saveForFuture: 0,
    };

    try {
      dispatch(fetchbooknAsync(payload));
    } catch (error) {
      console.error("signup failed:", error);
    }
  };

  const finalOrderBooking = (orderId) => {
    const payload = {
      bookingId: "655f03f5271db636682320d1",
      price: price,
      discount: discount,
      finalPrice: price,
      promoCodeId: "dzdcsads",
      paymentTransactionId: "dfhuasjdasmdasdhnj",
      paymentMethod: "Stripe",
    };
  };
  useEffect(() => {
    console.log("Booking Data ===>", bookingData);
    if (bookingData != null && bookingData.status === 1) {
      navigate("/StipePayment", {
        state: {
          address,
          date,
          time,
          counter,
          divs,
          latitude,
          longitude,
          categoryId,
          profileImage,
          price,
          firstName,
          lastName,
          nannyId,
        },
      });
    }
  }, [bookingData]);

  //const [paymentError, setPaymentError] = useState(null);
  //const stripe = useStripe();
  //const elements = useElements();

  //const handleSubmit = async (event) => {
  //    event.preventDefault();

  //    if (!stripe || !elements) {
  //        return;
  //    }

  //    const cardElement = elements.getElement(CardElement);

  //    const { error, paymentIntent } = await stripe.confirmCardPayment('{CLIENT_SECRET}', {
  //        payment_method: {
  //            card: cardElement,
  //            billing_details: {
  //                name: 'Jenny Rosen',
  //            },
  //        },
  //    });

  //    if (error) {
  //        setPaymentError(error.message);
  //    } else {
  //        console.log('Payment successful:', paymentIntent);
  //        // Redirect or show success message
  //    }
  //};

  useEffect(() => {
    console.log("Booking Detail ===> ", location.state);
    return () => {
      dispatch(clearBookingData());
    };
  }, []);

  return (
    <>
      <div className="book_detailsmain">
        <div className="book_details">
          <form>
            <div className="book_title">
              <div className="heading">
                <h4>Occasionl Nanny</h4>
                {/* <p>
                  <span>08 Aug</span> - <span>04:00PM</span>
                </p> */}
              </div>
              <div className="extendbooking">
                {/* <Link to="#"> */}
                <EditNoteIcon /> Extend Booking
                {/* </Link> */}
              </div>
            </div>
            <div className="description">
              <p>DESCRIPTION</p>
              <p>Subtotal</p>
            </div>
            <div className="nanny_info">
              <div className="nanny_img">
                <img
                  src={profileImage != "" ? profileImage : profile_icon}
                  alt="nanny"
                />
                <h5>
                  {firstName} {lastName}
                </h5>
              </div>
              <h5>${price}</h5>
            </div>
            <div className="redeem-icon">
              <h6>
                <span>
                  <RedeemIcon />
                </span>{" "}
                Redeem promotion
              </h6>
              <div className="modal_btn">
                <p onClick={handleShows}>
                  <ChevronRightIcon />
                </p>
              </div>
            </div>
            <div className="price_details">
              <ul>
                <div className="fee_price">
                  <ul>
                    <li>Base Fee</li>
                  </ul>
                  <p>${price}</p>
                </div>
                <div className="fee_price">
                  <ul>
                    <li>Discount</li>
                  </ul>
                  <p>${discount}</p>
                </div>
                {/* <div className="fee_price">
                  <ul>
                    <li>Credits</li>
                  </ul>
                  <p>$25</p>
                </div>
                <div className="fee_price">
                  <ul>
                    <li>Promotions</li>
                  </ul>
                  <p>0</p>
                </div> */}
              </ul>
            </div>
            <div className="total_price">
              <h4>Total</h4>
              <h4>${price}</h4>
            </div>
            <div className="booking_btn">
              {/* <CardElement /> */}
              <button
                type="button"
                className="btn_continue"
                onClick={() => handlebook()}
              >
                Confirm
              </button>

              {/* {paymentError && <div>{paymentError}</div>} */}
            </div>
          </form>
        </div>
      </div>
      <Modal show={show} onHide={handleCloses} className="redeem_modal mt-5">
        <Modal.Header closeButton>
          <Modal.Title>Redeem Promotion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="book_detailsmain">
            <div className="book_details mt-0">
              <Form className="redeem_form">
                <InputGroup className="mb-3">
                  <Form.Control placeholder="Enter Promo Code" />
                  <InputGroup.Text>
                    <p>Redeem</p>
                  </InputGroup.Text>
                </InputGroup>
              </Form>
              <div className="redeem_cards">
                <div className="heading">
                  <h6>AVAILABE COUPONS</h6>
                </div>
                <div className="card">
                  <div className="card_body">
                    <div className="card_heading">
                      <h5>Naturebreed</h5>
                      <p onClick={handleCloses} style={{ color: "#89d6ce" }}>
                        Apply
                      </p>
                    </div>
                    <div className="get_off">
                      <span>Get 50% off</span>
                    </div>
                    <div className="off_all">
                      <p>50% Off on all service</p>
                    </div>
                  </div>
                  <div className="card_end">
                    <p>
                      Offer valid <span>Today,till 05:00 pm</span>
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card_body">
                    <div className="card_heading">
                      <h5>Naturebreed</h5>
                      <Link
                        to="#"
                        onClick={handleCloses}
                        style={{ color: "#89d6ce" }}
                      >
                        Apply
                      </Link>
                    </div>
                    <div className="get_off">
                      <span>Get 50% off</span>
                    </div>
                    <div className="off_all">
                      <p>50% Off on all service</p>
                    </div>
                  </div>
                  <div className="card_end">
                    <p>
                      Offer valid <span>Today,till 05:00 pm</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Bookdetails;
