import React from "react";
import Modal from "react-modal";
import { Link, useNavigate } from "react-router-dom";
import profile_icon from "../../assets/img/profile_icon.png";

Modal.setAppElement("#root");

// bookdetails;
const Nannycard = ({
  profileImage,
  firstName,
  lastName,
  price,
  address,
  date,
  time,
  counter,
  divs,
  latitude,
  longitude,
  categoryId,
  nannyId,
}) => {
  const navigate = useNavigate();
  const navigateToAnotherScreen = () => {
    navigate("/bookdetails", {
      state: {
        address,
        date,
        time,
        counter,
        divs,
        latitude,
        longitude,
        categoryId,
        profileImage,
        price,
        firstName,
        lastName,
        nannyId,
      },
    });
  };

  const type = localStorage.getItem("type")
  const token = localStorage.getItem("Token")

  return (
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="nanny_list_main px-3 text-center py-4 shadow bg-body">
        <div className="img_thumanail">
          <img
            src={profileImage != "" ? profileImage : profile_icon}
            className="mb-3"
            alt="image"
          />
        </div>

        <h3>
          {" "}
          <span> {firstName} </span> <span>{lastName}</span>
        </h3>

        <ul>
          <li className="nany_small_details d-flex justify-content-between py-1">
            <h5>Price</h5>
            <h5>{price}</h5>
          </li>

          <li className="nany_small_details d-flex justify-content-between py-1">
            <h5>Time </h5>
            <h5>{time}</h5>
          </li>

          {/* <li className="nany_small_details d-flex justify-content-between py-1">
                        <h5>Address</h5>
                        <h5>{address}</h5>
                    </li> */}
        </ul>

        <div>
          {type != 1 &&
            <button
              className="btn btn-primary d-inline-block mt-2 all_btns px-4 py-2 position-relative"
              onClick={() => navigateToAnotherScreen(token != null ? "/nannycategories" : "/login")}
            >
              BOOK NANNY
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default Nannycard;
