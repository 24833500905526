import React from 'react';
import { Link } from 'react-router-dom';
import download_app from '../../assets/img/download_app.png'


const Download = () => {

    return (
        <>
            <div className="banner_bg_download position-absolute top-0 start-0"></div>
            <div className='container'>
                <div className=" banner_download d-flex align-items-center" style={{ marginBottom: "-10px" }}>
                    <div className="container">
                        <div className='row'>
                            <div className='col-sm-12 col-md-6'>
                                <h2 className="mb-2 download_head">Get the new App for
                                    iPhone & Android</h2>
                                <p className="mb-3">Mauris ut fermentum orci. Pellentesque ultrices interdum ultrices. Aliquam erat volutpat. Mauris molestie aliquam hendrerit. Integer cursus neque eget auctor finibus, at enim euismod.</p>
                                <div className='android_ios_btn'>
                                    <div>
                                        <Link to="#" className="btn btn-book mt-2 position-relative">Download for iOS</Link>
                                    </div>
                                    <div>
                                        <Link to="#" className="btn btn-book mt-2 position-relative">Download for Android</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 mobile_app_img'>
                                <div style={{ textAlign: 'center' }}>
                                    <img src={download_app} alt='download_app' style={{ width: '300px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Download