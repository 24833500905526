import { LocalPhone, LocationOn, MailOutline, WhatsApp } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import facebook from '../../assets/img/facebook.png';
import instagramIcon from '../../../src/assets/img/instagramIcon.jfif'
import twitter from '../../../src/assets/img/twitter.png'
import linkedin from '../../../src/assets/img/linkedin.png'
import WhatsApp_icon from '../../../src/assets/img/WhatsApp_icon.png.webp'


const Footer = () => {

    const navigation = useNavigate();

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <>
            <div className="footer_banner_bg sections_padding position-relative ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <h5 className="fw-700 mb-3 mb-md-5 mt-2 mt-md-0">About Us</h5>
                            <p>Our professional and experienced nannies are always available. You can rely on us daytime, nighttime, anytime!</p>
                            <br />
                            <p>We aim to take good care of our nannies as it’s important that you/they feel supported by the agency to ensure a positive working relationship. We understand that it’s crucial for everybody that the nanny is in a good place so that they deliver the best possible care.</p>
                        </div>
                        <div className="col-md-3 offset-md-1">
                            <h5 className="fw-700 mb-3 mb-md-5 mt-4 mt-md-0">Services</h5>
                            <ul>
                                <li onClick={() => { scrollToTop(); navigation('/services_details') }} style={{ cursor: 'pointer' }}>
                                    <a to="#" className="py-2 d-block">Hotel Bookings</a>
                                </li>
                                <li onClick={() => { scrollToTop(); navigation('/services_details') }} style={{ cursor: 'pointer' }}>
                                    <a to="#" className="py-2 d-block">Occasional  bookings</a>
                                </li>
                                <li onClick={() => { scrollToTop(); navigation('/services_details') }} style={{ cursor: 'pointer' }}>
                                    <a to="#" className="py-2 d-block">Company Nannies</a>
                                </li>
                                <li onClick={() => { scrollToTop(); navigation('/services_details') }} style={{ cursor: 'pointer' }}>
                                    <a to="#" className="py-2 d-block">Dog Sitting Services</a>
                                </li>
                                <li onClick={() => { scrollToTop(); navigation('/services_details') }} style={{ cursor: 'pointer' }}>
                                    <a to="#" className="py-2 d-block">Cleaning Services</a>
                                </li>
                                <li onClick={() => { scrollToTop(); navigation('/privacy_policy') }} style={{ cursor: 'pointer' }}>
                                    <p onClick={scrollToTop} className="py-2 d-block">Privacy policy</p>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h5 className="fw-700 mb-3 mb-md-5 mt-4 mt-md-0">Contact</h5>
                            <div className="d-flex align-items-center contact_section mb-4">
                                <LocationOn />
                                <p>Placa del Duc de Medicinalei 08002, Barcelona</p>
                            </div>
                            <div className="d-flex align-items-center contact_section mb-4">
                                <img src={WhatsApp_icon} alt='WhatsApp_icon' style={{ borderRadius: "50%", width: 33, height: 33, marginRight: 10 }} />
                                <p>+34 617 24 36 59</p>
                            </div>
                            <div className="d-flex align-items-center contact_section mb-3">
                                <MailOutline />
                                <p>info@nanny-line.com </p>
                            </div>
                            <div className="d-flex align-items-center contact_section mb-4">
                                <Link to="https://www.instagram.com/thenannyline.co/" target='_blank' >
                                    <img src={instagramIcon} alt='instagramIcon' style={{ borderRadius: "50%", width: 33, height: 33 }} className='me-3' />
                                </Link>
                                <Link to="https://www.facebook.com/thenannyline" target='_blank'>
                                    <img src={facebook} alt='facebook' style={{ borderRadius: "50%", width: 30, height: 30 }} className='me-3' />
                                </Link>
                                <Link to="https://x.com/thenannyline" target='_blank'>
                                    <img src={twitter} alt='twitter' style={{ borderRadius: "50%", width: 35, height: 35 }} className='me-3' />
                                </Link>
                                <Link to="https://www.linkedin.com/company/the-nanny-line/" target='_blank'>
                                    <img src={linkedin} alt='linkedin' style={{ borderRadius: "50%", width: 33, height: 33 }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <hr className="my-4" />
                </div>
            </div>
        </>
    )
}

export default Footer