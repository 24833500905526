import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/img/logo-nanny.png";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../store/apiSlice/LoginSlice";
import { Button } from "react-bootstrap";
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { ClipLoader } from "react-spinners";

const Login = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.rootReducer.login.data);
  const isLoading = useSelector((state) => state.rootReducer.login.loading);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const isAuthenticated = useSelector((state) => state.isAuthenticated);

  const [isValid, setIsValid] = useState(true);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValid(true);
    // Regular expression for basic email validation

  };

  const handleLogin = () => {

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,4}$/;
    const isValidEmail = emailRegex.test(email);
    setIsValid(isValidEmail);
    console.log("IS Valid ===> ", isValidEmail)
    if (email.length == 0) {
      alert("Please enter email!");
    } else if (password.length == 0) {
      alert("Please enter password");
    } else {
      if (isValidEmail) {
        const payload = {
          email: email,
          password: password,
          fcmToken: "Anmol",
        };
        dispatch(loginUser(payload));
      }
    }
  };

  const navigate = useNavigate();

  const navigateToAnotherScreen = () => {
    if (user.data.type == 1) {
      navigate("/profilenanny");
    } else {
      navigate("/");
    }
  };


  useEffect(() => {
    console.log("Login Data", user);
    if (user != null && user.status === 1) {
      if (localStorage.getItem("Token").length != null)
        navigateToAnotherScreen();
    } else if (user != null) {
      alert(user.message);
    }
  }, [user]);


  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container py-5">
          <a href="#" className="custom-logo d-block login-logo mx-auto">
            <img className="mw-100" src={logo} alt="logo" />
          </a>
          <div className="row">
            <div className="col-lg-6 offset-lg-3 offset-sm-1 col-sm-10">
              <div className="login-container">
                <form className="login-form" appearance="outline">
                  <h3>Welcome</h3>
                  <p>Please login to your account</p>
                  <div className="input-group">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => { handleEmailChange(e); setEmail(e.target.value) }}
                      placeholder="Email"
                    />

                    {email.length != 0 && !isValid ? (
                      <p style={{ color: "red", fontSize: "15px" }} className="mb-0 mt-1">
                        Invalid email address
                      </p>
                    ) : (
                      ""
                    )}

                  </div>
                  <div className="input-group mb-0">
                    <input
                      type="password"
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                  </div>
                  <p>
                    {" "}
                    {/* <Link
                      className="d-flex justify-content-end mt-2"
                      to="/forotpassword"
                    >
                      Forgot Password?
                    </Link> */}
                  </p>
                  <Button type="button" onClick={() => handleLogin()}>
                    {isLoading ? (
                      <ClipLoader color="#fff" />
                    ) : (
                      "Login"
                    )}
                  </Button>

                  {/*{error && <p style={{ color: 'red' }}>{error}</p>}*/}
                  <div className="bottom-text">
                    <div className="mt-3 bottom_link">
                      Im a new user.
                      <div className='flex_link'>
                        <p className='link_color' onClick={() => { scrollToTop(); navigate('/signupnanny') }} > {" "}REGISTER AS NANNY</p>
                        <span className='mx-1'> / </span>
                        <p className='link_color' onClick={() => { scrollToTop(); navigate('/signupfamily') }}>REGISTER AS FAMILY</p>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <GoogleLogin
                      onSuccess={handleSuccess}
                      onError={handleError}
                      useOneTap
                      flow="auth-code"
                    />
                  </div> */}

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
