// src/redux/cardsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, categoriesApi } from "../../utils/Constants";

// Async thunk for fetching data
export const categories = createAsyncThunk("categories", async () => {
    // const token = localStorage.getItem("Token");
    const headers = {
        // "Authorization": token,
        // "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
    };
    try {
        const url = BASE_URL + categoriesApi
        const response = await axios.get(url, { headers });
        // Replace with your API endpoint
        return response.data;
    } catch (error) {
        throw error;
    }
}
);

const categoriesSlice = createSlice({

    name: "categoriesReducer",
    initialState: {
        data: null,
        loading: "idle",
        error: null,
    },
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(categories.pending, (state) => {
                state.loading = "pending";
            })
            .addCase(categories.fulfilled, (state, action) => {
                state.loading = "idle";
                state.data = action.payload;
                state.error = null;
            })
            .addCase(categories.rejected, (state, action) => {
                state.loading = "idle";
                state.error = action.error.message;
            });
    },
});

export default categoriesSlice.reducer;
