import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { categories } from '../../store/apiSlice/categoriesSlice';

const ServicesDetail = () => {

    const dispatch = useDispatch();
    const categoriesData = useSelector((state) => state.rootReducer.categoriesReducer.data);
    const [nannycatData, setNannycatData] = useState(null);

    useEffect(() => {
        dispatch(categories());
    }, []);

    useEffect(() => {
        console.log("categoriesData ===>", categoriesData)
        if (categoriesData != null && categoriesData.status === 1) {
            setNannycatData(categoriesData.data);
        }
    }, [categoriesData]);


    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (

        <>
            <div className="pt-5 pb-4">
                <div className="container">
                    <h1 className="text-center mb-4 pt-4">
                        <span className="position-relative content_offer" style={{ color: "#89d6ce", fontWeight: "600" }}>Service Details</span>
                    </h1>

                    {nannycatData != null ?
                        nannycatData.map((item, index) =>
                            <div>
                                <div className="row mt-3 mt-md-4 mt-sm-3 row_padding row_center">
                                    <div className='col-12 col-sm-12 col-md-10 col-lg-8'>
                                        <div className="col-12 p-0">
                                            <img className="w-100 service_holiday_img download_app_img" src={"https://nanny-media.s3.eu-north-1.amazonaws.com/" + item.image} alt="image" />
                                        </div>
                                        <div className="col-12 service_text secondry_bg p-sm-2 p-2 p-md-0">
                                            <div className="hotel_book">
                                                <h2 className="mb-4 fw-700 nanny_card_header_name">{item.name}</h2>
                                                <p>{item.description}</p>
                                                <div className='text-center'>
                                                    <Link to="/login" onClick={scrollToTop} className="btn btn-primary d-inline-block mt-4 all_btns px-4 py-2 position-relative">Boook Now</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                </div>
            </div >
        </>
    )
}

export default ServicesDetail