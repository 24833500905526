import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCardsAsync } from "../../store/apiSlice/NcategoriesSlice";
import Card from "./Card";


const Nannycategories = () => {

  const dispatch = useDispatch();
  const data = useSelector((state) => state.rootReducer.cards.data);
  const [nannycatData, setNannycatData] = useState(null);

  useEffect(() => {
    dispatch(fetchCardsAsync());
  }, []);

  useEffect(() => {
    if (data != null) {
      console.log("Category ===> ", data.data)
      setNannycatData(data.data);
    }
  }, [data]);

  return (
    <>
      <div className=" nannycategories">
        <div className="banner_categories">
          <div className="heading_categories container">
            <h1>
              <span>Nanny</span>
            </h1>
            <h1>
              <span>Categories</span>
            </h1>
          </div>
        </div>

        <div className="container sections_padding">
          {nannycatData != null ? (
            <div className="row">
              {nannycatData.map((item) => (
                <Card
                  name={item.name}
                  image={"https://nanny-media.s3.eu-north-1.amazonaws.com/" + item.image}
                  categoryId={item._id}
                />
              ))}
            </div>
          ) : (
            ""
          )}

        </div>
      </div>
    </>
  );
};

export default Nannycategories;
