import React from 'react';

const PrivacyPolicy = () => {

    return (
        <>
            <div className='privacy_policy_inner'>
                <div className='container'>
                    <h3>Privacy Policy</h3>

                    <ol>
                        <li><b>Data Collection and Usage :</b> Our nanny app collects personal data such as name, contact information, and preferences to facilitate the matching process between caregivers and families. This data is used solely for the purpose of providing childcare services and enhancing user experience.</li>

                        <li><b>Data Deletion Policy :</b> Users have the right to request the deletion of their personal data from our servers and databases.</li>
                        <li>Upon receiving such requests, we will promptly and securely delete all relevant data, including contact information, messages, and any other personally identifiable information.</li>
                        <li>Deleted data will be permanently removed from all storage systems in compliance with applicable data protection laws.</li>
                        <li>Users can initiate a data deletion request through the app's settings or by contacting our customer support team.</li>
                        <li><b>Data Pull Policy :</b> We may periodically review and delete inactive accounts or outdated data to maintain data integrity and efficiency.</li>
                        <li>Inactive accounts may be subject to data pull procedures to ensure the accuracy and relevance of stored information.</li>
                        <li>Users will be notified in advance if their data is scheduled for deletion due to account inactivity.</li>
                        <li>Data pull processes adhere to strict security protocols to prevent unauthorized access or use of user data.</li>
                        <li><b>User Consent :</b> We obtain explicit consent from users before collecting, processing, or storing any personal data.</li>
                        <li>Users have full control over their data and can manage their privacy preferences through the app's settings.</li>
                        <li>By using our app, users consent to the collection and use of their personal data as outlined in this privacy policy.</li>
                        <li><b>Security Measures :</b> We implement robust security measures to protect users' personal data from unauthorized access, disclosure, alteration, or destruction.</li>
                        <li>Encryption, access controls, and regular security audits are employed to safeguard user privacy and prevent data breaches.</li>
                        <li><b>Data Retention :</b> We retain users' personal data only for as long as necessary to fulfill the purposes outlined in this privacy policy or as required by law.</li>
                        <li>After the retention period expires, data will be securely deleted or anonymized to prevent unauthorized access or use.</li>
                        <li><b>Transparency :</b> Our data deletion and data pull policies are transparently communicated in this privacy policy.</li>
                        <li>Users are informed about their rights regarding data deletion and the procedures for initiating deletion requests.</li>
                        <li>Any updates or changes to our privacy policy will be promptly communicated to users through the app or via email.</li>
                        <li>By using our nanny app, users agree to abide by the terms outlined in this privacy policy. For any questions or concerns regarding data deletion or privacy practices, users can contact our customer support team for assistance.</li>
                    </ol>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy