import React, { useEffect, useState } from 'react';
import about from "../../assets/img/about.jpg";
import wlogo from '../../assets/img/w-logo.png';
import wlogo1 from '../../assets/img/wlogo1.jpeg';
import wlogo2 from '../../assets/img/wlogo2.jpeg';
import wlogo3 from '../../assets/img/wlogo3.jpeg';
import wlogo4 from '../../assets/img/wlogo4.jpeg';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowForward, CheckBox, Drafts, Edit, Map, Star } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { categories } from '../../store/apiSlice/categoriesSlice';

const Home = () => {

    const type = localStorage.getItem("type")
    const token = localStorage.getItem("Token")

    const dispatch = useDispatch();
    const navigation = useNavigate();
    const categoriesData = useSelector((state) => state.rootReducer.categoriesReducer.data);
    const [nannycatData, setNannycatData] = useState(null);

    useEffect(() => {
        dispatch(categories());
    }, []);

    useEffect(() => {
        console.log("categoriesData ===>", categoriesData)
        if (categoriesData != null && categoriesData.status === 1) {
            setNannycatData(categoriesData.data);
        }
    }, [categoriesData]);



    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <>
            <div className="banner_bg_home position-absolute top-0 start-0"></div>
            <div className="baner_text_home d-flex align-items-center text-center">
                <div className="container">
                    <h1 className="mb-2">Welcome</h1>
                    <h2 className="mb-3">The <span>Nanny</span> Line
                    </h2>
                    {type != 1 &&
                        <Link to="/nannycategories" className="btn btn-book mt-4 position-relative">BOOK</Link>}
                </div>
            </div>
            <div className="who_we_section sections_padding">
                <div className="container text-center py-1 py-sm-4 secound_section">
                    <h3 style={{ fontWeight: 'bold' }}>Who We Are?</h3>
                    <h2 className="my-2 my-sm-3 pb-0 pb-sm-2">
                        <span className="position-relative babysitter">"More than a babysitter"</span>
                    </h2>
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <p className="mb-2">At The Nanny Line, we understand that finding the perfect nanny is about trust and reliability. We are delighted to connect
                                you with our international team of experienced, enthusiastic, and thoroughly vetted nannies. Whether at home, a hotel, or an Airbnb, booking
                                a nanny has never been easier or more secure.</p>
                            <Link to="/services" className="btn btn-primary d-inline-block mt-3 mt-sm-4 all_btns px-4 py-2 position-relative">Services</Link><br />
                            <span onClick={() => scrollToTop()}> {token === null && <Link to="/signupnanny" className="btn btn-primary d-inline-block mt-3 mt-sm-4 all_btns px-4 py-2 position-relative" >Apply to be Nanny</Link>}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sections_padding services_box secondry_bg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <h2 className="mb-0 mb-md-4 pb-3 offer_nanny">
                                <span className="position-relative">Where do we offer</span>
                            </h2>
                            <p className='mb-2'>Our nannies are available for locals, tourists or special occasions.</p>
                        </div>
                        <div className="col-md-4 pe-2">
                            <img className="mw-100" src={about} alt="offers" />
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                {nannycatData != null ?
                                    nannycatData.map((item) =>
                                        <div className="col-12 mb-2 mb-md-3 mb-lg-4 ps-3 ps-md-5 mt-3 mt-md-0">
                                            <div className="d-flex flex-column flex-md-row align-items-center">
                                                <div className="service_arrow mb-2 mb-md-0" onClick={() => navigation('/services_details')}>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        < ArrowForward />
                                                    </div>
                                                </div>
                                                <div className="service_info ps-0 ps-md-2 ps-lg-5">
                                                    <h5 className="fw-700">{item.name}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                {/* <div className="col-12 mb-4 ps-3 ps-md-5">
                                    <div className="d-flex flex-column flex-md-row align-items-center">
                                        <div className="service_arrow mb-3 mb-md-0">
                                            <div className='d-flex align-items-center justify-content-center'>
                                                < ArrowForward />
                                            </div>
                                        </div>
                                        <div className="service_info ps-0 ps-md-5">
                                            <h5 className="mb-3 fw-700">Occasional bookings</h5>
                                            <p>We provide the best babysitter service all around in Barcelona. In your home? In your hotel room? It does not matter, we are available <span className="fw-700">anywhere!</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-4 ps-3 ps-md-5">
                                    <div className="d-flex flex-column flex-md-row align-items-center">
                                        <div className="service_arrow mb-3 mb-md-0">
                                            <div className='d-flex align-items-center justify-content-center'>
                                                < ArrowForward />
                                            </div>
                                        </div>
                                        <div className="service_info ps-0 ps-md-5">
                                            <h5 className="mb-3 fw-700">Occasional bookings</h5>
                                            <p>We provide the best babysitter service all around in Barcelona. In your home? In your hotel room? It does not matter, we are available <span className="fw-700">anywhere!</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-4 ps-3 ps-md-5">
                                    <div className="d-flex flex-column flex-md-row align-items-center">
                                        <div className="service_arrow mb-3 mb-md-0">
                                            <div className='d-flex align-items-center justify-content-center'>
                                                < ArrowForward />
                                            </div>
                                        </div>
                                        <div className="service_info ps-0 ps-md-5">
                                            <h5 className="mb-3 fw-700">Occasional bookings</h5>
                                            <p>We provide the best babysitter service all around in Barcelona. In your home? In your hotel room? It does not matter, we are available <span className="fw-700">anywhere!</span></p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="numbers_speaks sections_padding">
                <div className="container">
                    <h2 className="text-center pb-3">
                        <span className="position-relative">Numbers Speak</span>
                    </h2>
                    <p className="text-center">If you don`t believe us, believe in the numbers below </p>
                    <div className="mt-lg-5 mt-md-4 mt-3 row gx-0">
                        <div className="col-sm-6 col-lg-3 mb-3">
                            <div className="number_say_box text-center px-4 py-5">
                                <h2 className="mb-3 fw-700">8</h2>
                                <h6>Years of experience</h6>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3">
                            <div className="number_say_box text-center px-4 py-5">
                                <h2 className="mb-3 fw-700">300+
                                </h2>
                                <h6>Active nannies</h6>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3">
                            <div className="number_say_box text-center px-4 py-5">
                                <h2 className="mb-3 fw-700">24/7</h2>
                                <h6>Available</h6>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3">
                            <div className="number_say_box text-center px-4 py-5">
                                <h2 className="mb-3 fw-700">5840+</h2>
                                <h6>Families</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wny_us sections_padding">
                <div className="container">
                    <h2 className="pb-3  mb-lg-4 mb-md-2 mb-0 text-center">
                        <span className="position-relative">Why choose us?
                        </span>
                    </h2>
                    <p className="text-center">Daytime, nighttime, ANYTIME!</p>
                    <div className="row mt-5">
                        <div className="col-sm-6 col-lg-3 mb-3  ">
                            <div className="services_box secondry_bg pb-5 px-4 h-100">
                                <div className="choose_icon mb-5 d-inline-block">
                                    <Map /></div>
                                <h4 className="mb-4 fw-700">Hotel availability</h4>
                                <p>The Nanny Line has partnered with 5-star hotels across the globe to provide exceptional babysitting services wherever you are. Book The Nanny Line for your next hotel stay and experience top-notch childcare service. Contact us now to ensure a nanny is available for your upcoming trip.
                                </p>
                            </div>

                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3 why_2">
                            <div className="services_box secondry_bg pb-5 px-4  h-100">
                                <div className="choose_icon mb-5 d-inline-block">
                                    <Edit /></div>
                                <h4 className="mb-4 fw-700">Experienced and professional</h4>
                                <p>We are with full of competent and qualified nannies. How can we guarantee it? Every nanny in our database has at least 3+ years of experience!</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3 why_3">
                            <div className="services_box secondry_bg pb-5 px-4 h-100">
                                <div className="choose_icon mb-5 d-inline-block">
                                    <Drafts /></div>
                                <h4 className="mb-4 fw-700">Personally checked background</h4>
                                <p>Before applying to be a nanny at The Nanny Line, we request a background check from the given person. All of our nannies are personally checked for criminal background!</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 mb-3 why_4">
                            <div className="services_box secondry_bg pb-5 px-4 h-100">
                                <div className="choose_icon mb-5 d-inline-block">
                                    <CheckBox /></div>
                                <h4 className="mb-4 fw-700">Full availability</h4>
                                <p>Our nannies are available through every day of the week. Would you go for a late night dinner? No worries, our babysitters will be available for 24/7!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="testimonials">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="mb-2 text-center">
                                <span className="position-relative" style={{ color: '#89d6ce' }}>Our Happy Moms</span>
                            </h2>
                            <div className='d-flex justify-content-center'>
                                <p className='mb-3 customer_style'>Meet our satisfied customers. During the whole process of service what we offer, we aim to give the easiest and calmest procedure what we can!</p>
                            </div>
                        </div>

                        <div className="row mb-3  align-items-center">
                            <div className="col-md-2 col-sm-3">
                                <div className="d-flex testimonials_box py-4 px-3 align-items-center justify-content-center">
                                    <div className="d-block">
                                        <h6 className="fw-700 mb-2">Astrid Aafjes</h6>
                                        <h6>TNL Mom</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-10 mt-3 mt-sm-0 col-sm-9">
                                <div className="stars d-flex align-items-center mb-2 filter_star">
                                    <Star />
                                    <Star />
                                    <Star />
                                    <Star />
                                    <Star />
                                </div>
                                <h5 className="fw-700">“We had a lovely babysitter who was super cool with our daughter and her group of friends.”</h5>
                            </div>
                        </div>
                        {/* <div className="col-md-4 mb-4">
                            <div className="testimonials_box py-5 px-4">
                                <h5 className="mb-4 fw-700">“We had a lovely babysitter who was super cool with our daughter and her group of friends.”</h5>
                                <hr />
                                <div className="d-flex pt-4 align-items-center justify-content-between">
                                    <div className="d-block">
                                        <h6 className="fw-700">Astrid Aafjes</h6>
                                        <h6>TNL Mom</h6>
                                    </div>
                                    <div className="stars d-flex align-items-center ">
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-md-4 mb-4">
                            <div className="testimonials_box py-5 px-sm-4 px-md-2 px-md-4 mt-0">
                                <h5 className="mb-4 fw-700">“I am very happy with The Nanny Line. Every time I called them they were professional, responsive and sent me a very good babysitter”</h5>
                                <hr />
                                <div className="d-flex pt-4 align-items-center justify-content-between">
                                    <div className="d-block">
                                        <h6 className="fw-700">Maria Garrido Gabaldon</h6>
                                        <h6>TNL Mom</h6>
                                    </div>
                                    <div className="stars d-flex align-items-center">
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="row mb-3 flex-row-reverse align-items-center">
                            <div className="col-md-2 col-sm-3">
                                <div className="d-flex testimonials_box py-4 px-3 align-items-center justify-content-center">
                                    <div className="d-block">
                                        <h6 className="fw-700 mb-2">Maria Garrido Gabaldon</h6>
                                        <h6>TNL Mom</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-10 mt-3 mt-sm-0 col-sm-9">
                                <div className="stars d-flex align-items-center mb-2 filter_star">
                                    <Star />
                                    <Star />
                                    <Star />
                                    <Star />
                                    <Star />
                                </div>
                                <h5 className="fw-700 ml-3">“I am very happy with The Nanny Line. Every time I called them they were professional, responsive and sent me a very good babysitter”</h5>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row mb-3 align-items-center">
                                <div className="col-md-2 col-sm-3">
                                    <div className="d-flex testimonials_box py-4 px-3 align-items-center justify-content-center">
                                        <div className="d-block">
                                            <h6 className="fw-700 mb-2">Denitsa HB</h6>
                                            <h6>TNL Mom</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-10 mt-3 mt-sm-0 col-sm-9">
                                    <div className="stars d-flex align-items-center mb-2 filter_star">
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                    </div>
                                    <h5 className="fw-700">“Great service - reliable, quick and professional. My baby loved his nanny, and I was satisfied that he was taken care of!”</h5>
                                </div>
                            </div>
                            <div className="row  flex-row-reverse align-items-center">
                                <div className="col-md-2 col-sm-3">
                                    <div className="d-flex testimonials_box py-4 px-3 align-items-center justify-content-center">
                                        <div className="d-block">
                                            <h6 className="fw-700 mb-2">Sab Latie</h6>
                                            <h6>TNL Dad</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-10 mt-3 mt-sm-0 col-sm-9">
                                    <div className="stars d-flex align-items-center mb-2 filter_star">
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                    </div>
                                    <h5 className="fw-700 ml-3">“Nanny Line is a great service, very helpful and reactive! We had Olga at home when our daughter was sick and she was lovely!! We recommend her! Thanks so much!”</h5>
                                </div>
                            </div>
                            <div className="row mb-3 mt-3 align-items-center">
                                <div className="col-md-2 col-sm-3">
                                    <div className="d-flex testimonials_box py-4 px-3 align-items-center justify-content-center">
                                        <div className="d-block">
                                            <h6 className="fw-700 mb-2">Joanne Diaz</h6>
                                            <h6>TNL Mom</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-10 mt-3 mt-sm-0 col-sm-9">
                                    <div className="stars d-flex align-items-center mb-2 filter_star">
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                        <Star />
                                    </div>
                                    <h5 className="fw-700">“We had a great experience with Nanny Line. Our babysitter was smart, witty, competent, and very gentle with our son. We would definitely use this service again.”</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="prtners sections_padding">
                <div className="container">
                    <h2 className="text-center fw-700 mb-4 pb-3">
                        <span className="position-relative">Our partners
                        </span>
                    </h2>
                    <p className="text-center mb-3">We are proud to partner with more than 50 luxury hotels globally!</p>
                    <div className="row">
                        <div className="col text-center">
                            <img className="mw-100 wlogo_img" src={wlogo} alt="wlogo" />
                        </div>
                        <div className="col text-center">
                            <img className="mw-100 wlogo_img" src={wlogo2} alt="wlogo2" />
                        </div>
                        <div className="col text-center">
                            <img className="mw-100 wlogo_img" src={wlogo1} alt="wlogo1" />
                        </div>
                        <div className="col text-center">
                            <img className="mw-100 wlogo_img" src={wlogo4} alt="wlogo4" />
                        </div>
                        <div className="col text-center">
                            <img className="mw-100 wlogo_img" src={wlogo3} alt="wlogo3" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home