import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchbooknAsync } from "../../store/apiSlice/BooknannySlice";
import { Add, AddLocation, Remove } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import PlaceSearch from "../map/Googlemap";

const Card = ({ name, image, length, categoryId }) => {
  const [show, setShow] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const navigateToAnotherScreen = () => {
    navigate("/nanyList", {
      state: {
        address,
        date,
        time,
        counter,
        divs,
        latitude,
        longitude,
        categoryId,
      },
    });
  };

  const [isSecondModalOpen, setSecondModalOpen] = useState(false);

  const dispatch = useDispatch();
  // const data = useSelector((state) => state.rootReducer.bookNanny.data);

  const [address, setaddress] = useState("");
  const [date, setdate] = useState("");
  const [time, settime] = useState("");
  const [price, setprice] = useState("");
  const [childAge, setChildAge] = useState("");
  const [childName, setChildName] = useState("");
  const [dogSittingService, setDogSittingService] = useState(true);

  // console.log("Book Nanny ===>", data);

  const [divArray, setDivArray] = useState(
    Array.from({ length }, (_, index) => index + 1)
  );

  const handleRemove = (indexToRemove) => {
    const updatedArray = divArray.filter((_, index) => index !== indexToRemove);
    setDivArray(updatedArray);
  };

  const [divs, setDivs] = useState([
    {
      id: 0,
      name: childName,
      age: childAge,
    },
  ]);
  const addDiv = () => {
    const newDiv = {
      id: divs.length + 1,
      name: childName,
      age: childAge,
    };
    setDivs([...divs, newDiv]);
    // Clear input fields after adding a new div
    setChildName("");
    setChildAge("");
  };
  const removeDiv = () => {
    if (divs.length > 0) {
      setDivs(divs.slice(0, divs.length - 1));
    }
  };

  const [counter, setCounter] = useState(2);

  const handleincremented = () => {
    setCounter(counter + 1);
  };

  const handledecremented = () => {
    if (counter > 2) {
      setCounter(counter - 1);
    }
  };

  const handleNextPageClick = () => {
    if (
      address.trim() !== "" &&
      date.trim() !== "" &&
      divs[0].name.trim() !== "" &&
      divs[0].age.trim() !== ""
    ) {
      navigateToAnotherScreen(
        address,
        date,
        time,
        counter,
        divs,
        latitude,
        longitude,
        categoryId
      );
    } else {
      alert("Please fill all fields !");
    }
  };
  // Function to handle changing child name according to index
  const handleChangeName = (index, value) => {
    const updatedDivs = [...divs];
    updatedDivs[index].name = value;
    setDivs(updatedDivs);
  };

  // Function to handle changing child age according to index
  const handleChangeAge = (index, value) => {
    const updatedDivs = [...divs];
    updatedDivs[index].age = value;
    setDivs(updatedDivs);
  };

  useEffect(() => {
    // Function to get current location
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log("GeoLocation ===> ", position.coords.latitude);
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            setError(null);
          },
          (error) => {
            setError(error.message);
          }
        );
      } else {
        setError("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, [showMap]);

  const handleTimeChange = (time) => {
    const dateObject = new Date(time); // Convert input string to Date object

    // Convert to Indian Standard Time (IST)
    dateObject.setHours(dateObject.getHours()); // Add 5 hours for UTC+5
    dateObject.setMinutes(dateObject.getMinutes()); // Add 30 minutes for UTC+5:30

    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes().toString().padStart(2, "0"); // Get minutes

    // Determine AM or PM
    const amOrPm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12 || 12; // If hours is 0, use 12 for 12-hour format

    const timeString = `${hours}:${minutes} ${amOrPm}`;

    console.log(timeString);
    settime(timeString);
  };

  return (
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="nannay_cat_main d-block shadow bg-body rounded overflow-hidden">
        <Link onClick={handleShow}>
          <div className="category_image">
            <img src={image} className="w-100" alt={name} />
          </div>
          <div className="px-2 pt-2 categoty_heading w-100 d-flex justify-content-between align-items-center">
            <h6 className="m-0">{name}</h6>
            <Link to="#" className="arrow_right">
              <ArrowRightAltIcon />
            </Link>
          </div>
        </Link>
      </div>
      <Modal show={show} onHide={handleClose} className="add_detalis">
        <Modal.Header closeButton>
          <Modal.Title>ADD DETAILS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="w-100 form_eyes">
            <div className="mb-3">
              <label>Location</label>
              <input
                type="text"
                placeholder="location"
                value={address}
                onChange={(e) => setaddress(e.target.value)}
                style={{ paddingRight: "35px" }}
              />
              <div onClick={() => setShowMap(true)}>
                <AddLocation />
              </div>
            </div>

            <div className="mb-3">
              <Form.Label className="mb-0">Street Name</Form.Label>
              <input
                type="text"
                placeholder="street name"
              // value={date}
              // onChange={(e) => setdate(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <Form.Label className="mb-0">House Number</Form.Label>
              <input
                type="text"
                placeholder="house number"
              // value={date}
              // onChange={(e) => setdate(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <Form.Label className="mb-0">Apartment Number</Form.Label>
              <input
                type="text"
                placeholder="apartment number"
              // value={date}
              // onChange={(e) => setdate(e.target.value)}
              />
            </div>

          </form>
          <Form className="mb-2">
            <Form.Label className="mb-0">Date</Form.Label>
            <input
              type="date"
              name="duedate"
              placeholder="Due date"
              value={date}
              onChange={(e) => setdate(e.target.value)}
            />
          </Form>
          <Form className="mb-2">
            <Form.Label className="mb-0">Time</Form.Label>
            <div className="timepicker_card">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"en"}
                ampm={false}
                value={time}
                onChange={(e) => settime(e.target.value)}
              >
                <TimePicker onChange={handleTimeChange} />
              </LocalizationProvider>
            </div>
          </Form>
          {/* <Form className='mb-2'>
            <Form.Label>Time</Form.Label>
            <Form.Select aria-label="Default select example">
              <option>6:00 pm</option>
              <option value="1">5:00 pm</option>
              <option value="2">7:00 pm</option>
              <option value="3">8:00 pm</option>
            </Form.Select>
          </Form> */}
          <form className="w-100 mb-2 form_eyes">
            <label>Number of Hours</label>
            <input value={counter} />

            <Link onClick={handleincremented}>
              <Add className="incremented" />
            </Link>
            <Link onClick={handledecremented} className="decremented">
              <Remove />
            </Link>
          </form>



          {name != "Dog Sitting Services:" ?
            <div>
              <form>
                <div>
                  <div className="d-flex justify-content-between mt-2">
                    <label>Childern Detail</label>
                    <Link className="me-1" onClick={addDiv}>
                      <Add className="addicon" />
                    </Link>
                  </div>
                  {/* <div>
                <input
                  type="text"
                  placeholder="Name"
                  className="my-2 w-100"
                  value={childName}
                  onChange={(e) => setChildName(e.target.value)}
                />
                <input
                  type="Number"
                  placeholder="Age"
                  className="my-2 w-100"
                  value={childAge}
                  onChange={(e) => setChildAge(e.target.value)}
                />
              </div> */}
                </div>
              </form>
              <div>
                {divs.map((div, index) => (
                  <div key={div.id} className="added-div">
                    <div className="d-flex justify-content-between">
                      {index != 0 ? `Child ${index + 1}` : ""}
                      {index != 0 && (
                        <Link onClick={removeDiv}>
                          <Remove />
                        </Link>
                      )}
                    </div>
                    <div>
                      <form>
                        <input
                          type="text"
                          placeholder="Name"
                          className="my-2 w-100"
                          value={div.name || ""}
                          onChange={(e) => handleChangeName(index, e.target.value)}
                        />
                        <input
                          type="Number"
                          placeholder="Age"
                          className="my-2 w-100"
                          value={div.age || ""}
                          onChange={(e) => handleChangeAge(index, e.target.value)}
                        />
                        <input
                          type="text"
                          placeholder="Extra Info"
                          className="mt-2 w-100"
                          // value={div.age || ""}
                          // onChange={(e) => handleChangeAge(index, e.target.value)}
                          style={{ marginBottom: 20 }}
                        />
                      </form>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            :
            ""
          }

        </Modal.Body>
        <Modal.Footer className="btn_modal mt-0">
          <Button onClick={() => handleNextPageClick()}>CONTINUE</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showMap}
        onHide={() => setShowMap(false)}
        className="add_detalis"
      >
        <Modal.Header closeButton>
          <Modal.Title>My Address</Modal.Title>
        </Modal.Header>
        <div style={{ paddingBottom: 20 }}>
          {error && <p>Error: {error}</p>}
          {latitude && longitude && (
            <div>
              {/* <p>Latitude: {latitude}</p>
              <p>Longitude: {longitude}</p> */}
              <PlaceSearch
                lat={latitude}
                lng={longitude}
                setAddress={setaddress}
                setLatitude={setLatitude}
                setLongitude={setLongitude}
              />
              {/* Your mapping component here, passing latitude and longitude as props */}
            </div>
          )}
          <div style={{ textAlign: "center" }}>
            <Button onClick={() => setShowMap(false)}>OK</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Card;
