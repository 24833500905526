//Base_URL
export const BASE_URL = "https://dev-api-nanny.virtualittechnology.com";

//Api Name
export const OngoingUserApi = "/v1/user/getMyBookings?type=0"
export const upcommingUserApi = "/v1/user/getNanniesBookings"
export const UserprofileApi = "/v1/user/profile"
export const UserEditProfileApi = "/v1/user/updateProfile"
export const AddratingbookingApi = "/v1/user/addRatingToBooking"
export const addressUserApi = "/v1/user/getAddresses"

//Nanny Api's
export const profileNanny = "/v1/nanny/profile"
export const updateProfileNanny = "/v1/nanny/updateProfile"
export const updateBookingStatusApi = "/v1/nanny/updateBookingStatus"
export const DocumentsnannyApi = "/v1/nanny/getDocuments"
export const getAllCategoriesApi = "/v1/nanny/getAllCategories"
export const uploadDocumentsApi = "/v1/nanny/uploadDocuments"
export const gettimingApi = "/v1/nanny/getTimings"
export const updateTimingsApi = "/v1/nanny/updateTimings"
export const deleteTimingsApi = "/v1/nanny/deleteTimings"
export const NannyBookingApi = "/v1/nanny/getMyBookings"
export const UpdateNannyCatgories = "/v1/nanny/updateNannyCatgories"
export const categoriesApi = "/v1/user/categories"

//Common Api's
export const changePassword = "/v1/common/changePassword"
export const uploadFileApi = "/v1/upload"
export const chathistoryApi = "/v1/common/getChatHistory"
