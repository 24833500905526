import React, { useEffect, useState } from 'react'
import OTPInput from 'react-otp-input'
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/img/logo-nanny.png';
import { useDispatch, useSelector } from 'react-redux';
import { clearOtpData, verifyOtp } from '../../store/apiSlice/Otpslice';
import { clearSignupData } from '../../store/apiSlice/Signupnannyslice';
import { clearFamilyData } from '../../store/apiSlice/SignupfamilySilce';
import { ClipLoader } from "react-spinners";

const Otp = () => {

    const [otp, setOtp] = useState('');

    const dispatch = useDispatch();
    const data = useSelector((state) => state.rootReducer.otp.data);
    const isLoading = useSelector((state) => state.rootReducer.otp.loading);
    const signUpData = useSelector((state) => state.rootReducer.signUp.data);
    const signUpDataFamily = useSelector((state) => state.rootReducer.signUpfamily.data);

    //const [otp, setotp] = useState('');


    const handleOtpVerification = async () => {
        const emailVerify =
            signUpData != null ? signUpData.data.otp == otp ? 1 : signUpData.data.otpPhone == otp ? 2 : 3 : signUpDataFamily.data.otp == otp ? 1 : signUpDataFamily.data.otpPhone == otp ? 2 : 3
        if (emailVerify != 3) {
            const payload = {
                _id: signUpData != null ? signUpData.data._id : signUpDataFamily.data._id,
                otp: otp,
                emailVerify: emailVerify,
            }

            try {
                dispatch(verifyOtp(payload));
            } catch (error) {
                console.error('otp failed:', error);
            }
        } else if (otp.length == 0) {
            alert("Please enter your otp");
        } else if (otp.length == 1) {
            alert("Please enter your full otp");
        } else if (otp.length == 2) {
            alert("Please enter your full otp");
        } else if (otp.length == 3) {
            alert("Please enter your full otp");
        } else if (otp.length == 4) {
            alert("Please enter your full otp");
        } else if (otp.length == 5) {
            alert("Please enter your full otp");
        }
        else {
            console.log("Invalid OTP");
        }
    };


    const navigate = useNavigate();

    const navigateToAnotherScreen = () => {
        dispatch(clearFamilyData());
        dispatch(clearOtpData());
        dispatch(clearSignupData());
        navigate('/login');
    }

    useEffect(() => {
        console.log("data login ===>", data)
        if (data != null && data.status === 1) {
            navigateToAnotherScreen();
        }
    }, [data])

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="container py-5">
                        <a href="#" className="custom-logo d-block login-logo mx-auto">
                            <img className="mw-100" src={logo} alt="logo" />
                        </a>
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3 offset-sm-1 col-sm-10">
                                <div className="login_layout p-5 shadow-sm p-3 mb-5 bg-body rounded">
                                    <div className='otp_text'>
                                        <h6>OTP Verification</h6>
                                    </div>
                                    <form className="w-100 mb-4 input_otp" appearance="outline">
                                        <OTPInput
                                            value={otp}
                                            onChange={(value) => setOtp(value)}
                                            numInputs={6}
                                            renderSeparator={<span className='space'></span>}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                        {/*<OTPInput
                                            numInputs={6}
                                            renderSeparator={<span className='space'></span>}
                                            renderInput={(props) => <input {...props} />}
                                        />*/}
                                    </form>
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <button type='button' color="primary" className="login_button px-4 btn all_btn" onClick={() => handleOtpVerification()} >
                                            {isLoading ? (
                                                <ClipLoader color="#fff" />
                                            ) : (
                                                "Verify"
                                            )}
                                        </button>

                                        {/*{otpVerificationError && <p style={{ color: 'red' }}>Found Error</p>}*/}
                                        {/* <p className="mt-3"><Link to="/signup" >Resend</Link> One Time Password</p> */}
                                        {/* <p onClick={() => navigate('/forotpassword')} className='mt-3' style={{ color: "#0d6efd" }}>Entered a wrong password ?</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default Otp