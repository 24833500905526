import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchnlistAsync } from "../../store/apiSlice/NanylistSlice";
import Nannycard from "./Nannycard";
import { useLocation } from "react-router-dom";

const Nanylisting = ({ routes }) => {
  const location = useLocation();

  // Accessing the values from location.state
  const {
    address,
    date,
    time,
    counter,
    divs,
    latitude,
    longitude,
    categoryId,
  } = location.state;

  const dispatch = useDispatch();
  const data = useSelector((state) => state.rootReducer.Nlist.data);

  const [nannyListData, setNannyListData] = useState(null);

  useEffect(() => {
    console.log("address", location.state);
    dispatch(fetchnlistAsync());
  }, []);

  useEffect(() => {
    console.log("fetchnlistAsync data ===>", data);
    if (data != null) {
      setNannyListData(data.data.data);
    }
  }, [data]);

  return (
    <>
      <div className="">
        <div className="nanny_list_box ">
          <div className="nanny_banner">
            <div className="heading_categories container">
              <h1>
                <span>Part Time </span>
              </h1>
              <h1>
                <span>And</span>
              </h1>
              <h1>
                <span>Full Time</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="container sections_padding">
          {nannyListData != null ? (
            <div className="row">
              {nannyListData.map((item) => (
                <Nannycard
                  firstName={item.nannyId.firstName}
                  profileImage={item.nannyId.profileImage}
                  price={item.price}
                  lastName={item.nannyId.lastName}
                  address={address}
                  date={date}
                  time={time}
                  counter={counter}
                  divs={divs}
                  latitude={latitude}
                  longitude={longitude}
                  categoryId={categoryId}
                  nannyId={item.nannyId._id}
                />
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Nanylisting;
