import React, { useEffect, useState } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import GoogleMapReact from "google-map-react";

const PlaceSearch = ({
  google,
  lat,
  lng,
  setAddress,
  setLatitude,
  setLongitude,
}) => {
  const [value, setValue] = useState(null);

  const handleSelect = (place) => {
    // Here, you can handle the selection of the place, if needed.
    console.log("place ===>", place);
  };

  useEffect(() => {
    setAddress(value != null ? value.label : "");
    console.log(value != null ? value.label : value);
  }, [value]);

  const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    value != null ? value.label : value
  )}&key=${"AIzaSyC9tma-o_bhWEIGAabUP5vgLUBKa-ip9Xw"}`;

  fetch(geocodeUrl)
    .then((response) => response.json())
    .then((data) => {
      if (data.status === "OK" && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        const latitude = location.lat;
        const longitude = location.lng;
        setLatitude(latitude);
        setLongitude(longitude);
        console.log("Latitude:", latitude);
        console.log("Longitude:", longitude);
      } else {
        console.error("Failed to geocode the address");
      }
    })
    .catch((error) => {
      console.error("Error fetching geocoding data:", error);
    });

  return (
    <div className="custom_containers p-2">
      <div style={{ height: "70vh", width: "100%" }}>
        <GooglePlacesAutocomplete
          apiKey="AIzaSyC9tma-o_bhWEIGAabUP5vgLUBKa-ip9Xw"
          onSelect={handleSelect}
          selectProps={{
            value,
            onChange: setValue,
          }}
        />
        <div style={{ height: "60vh", marginTop: "15px" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyC9tma-o_bhWEIGAabUP5vgLUBKa-ip9Xw",
            }}
            center={{ lat: lat, lng: lng }}
            defaultCenter={{ lat: lat, lng: lng }} // Change to your desired default center
            defaultZoom={10} // Change to your desired default zoom level
          >
            {/* {places.map((place) => (
              <AnyReactComponent
                key={place.place_id}
                lat={place.geometry.location.lat()}
                lng={place.geometry.location.lng()}
              />
            ))} */}
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyC9tma-o_bhWEIGAabUP5vgLUBKa-ip9Xw",
})(PlaceSearch);
