import React, { useEffect, useState } from 'react';
import profile_icon from '../../assets/img/profile_icon.png';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Earning from './Earning';
import { useDispatch, useSelector } from 'react-redux';
import Manageservices from './Manageservices';
import Documentsnanny from './Documentsnanny';
import Contactus from './Contactus';
import { getNannyProfile } from '../../store/apiSlice/NannyprofileSlice';
import NeditProfile from './NeditProfile';
import Nannybooking from '../Nannybooking/Nannybooking';
import Availability from '../Availability/Availability';
import NannyPayments from './NannyPayments';


const Profilenanny = () => {

    const dispatch = useDispatch();
    const profileData = useSelector((state) => state.rootReducer.nannyProfileReducer.data);

    const [NannyEarning, setNannyEarning] = useState(null)
    const [nannyProfile, setNannyProfile] = useState(null)

    useEffect(() => {
        dispatch(getNannyProfile());
    }, []);

    useEffect(() => {

        console.log("Profile Data ===> ", profileData)
        if (profileData != null && profileData.status === 1) {
            setNannyProfile(profileData.data)
        }
        else if (profileData != null) {
            alert(profileData.message)
        }
    }, [profileData])




    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);


    return (
        <>
            <div className="coustom_container">
                <div className='top-padding'>
                    <Tabs>
                        <div className="row mb-5">
                            <div className="col-lg-3 mb-3 mb-md-0 h-100">
                                <div className="card  rounded overflow-hidden p-3 card_height">
                                    {nannyProfile != null &&
                                        <div className="profile_photo text-center p-2">
                                            <img src={nannyProfile != null && nannyProfile.profileImage != "" ? nannyProfile.profileImage : profile_icon} alt="profile" />
                                            <h4 className="mt-3">{nannyProfile.firstName} {nannyProfile.lastName}</h4>
                                        </div>
                                    }
                                    <TabList className="profile_edit_options profile_option_flex">
                                        <Tab className="profile_option" onClick={scrollToTop} >
                                            <span className="ms-0 ms-lg-2">My Booking</span>
                                        </Tab >
                                        <Tab className="profile_option" onClick={scrollToTop} >
                                            <i className="fa fa-edit"></i>
                                            <span className="ms-0 ms-lg-2">My Profile</span>
                                        </Tab>
                                        <Tab className="profile_option" onClick={scrollToTop} >
                                            <span className="ms-0 ms-lg-2">Earning</span>
                                        </Tab>
                                        <Tab className="profile_option" onClick={scrollToTop} >
                                            <span className="ms-0 ms-lg-2">Payments</span>
                                        </Tab>
                                        <Tab className="profile_option" onClick={scrollToTop} >
                                            <span className="ms-0 ms-lg-2">Manage Services</span>
                                        </Tab >
                                        <Tab className=" profile_option" onClick={scrollToTop} >
                                            <span className="ms-0 ms-lg-2">Documents</span>
                                        </Tab >
                                        <Tab className="profile_option" onClick={scrollToTop} >
                                            <span className="ms-0 ms-lg-2">Contact Us</span>
                                        </Tab >
                                        <Tab className="profile_option" onClick={scrollToTop} >
                                            <span className="ms-0 ms-lg-2">Availability</span>
                                        </Tab>
                                    </TabList >

                                </div>
                            </div >
                            <div className="col-lg-9">
                                <div className="card  rounded p-3 card_height">
                                    <TabPanel>
                                        <Nannybooking />
                                    </TabPanel>

                                    {/* <TabPanel>
                                        {nannyProfile !== null &&
                                            <Nannyprofile nannyData={nannyProfile} />
                                        }

                                    </TabPanel> */}

                                    <TabPanel>
                                        {nannyProfile !== null &&
                                            <NeditProfile nannyData={nannyProfile} />
                                        }
                                    </TabPanel>

                                    <TabPanel>
                                        <Earning />
                                    </TabPanel>

                                    <TabPanel>
                                        {/* <NannyPayments /> */}
                                    </TabPanel>

                                    <TabPanel>
                                        <Manageservices />
                                    </TabPanel>

                                    <TabPanel>
                                        <Documentsnanny />
                                    </TabPanel>
                                    <TabPanel>
                                        <Contactus />
                                    </TabPanel>
                                    <TabPanel>
                                        <Availability />
                                    </TabPanel>
                                </div>
                            </div >
                        </div>
                    </Tabs>
                </div>
            </div >
        </>
    )
}

export default Profilenanny
