import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/img/logo-nanny.png";
import { useDispatch, useSelector } from 'react-redux';
import { familyUser } from '../../../store/apiSlice/SignupfamilySilce';
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2';
// import { useGoogleLogin } from '@react-oauth/google';
// import { GoogleLogin } from '@react-oauth/google';
import { ClipLoader } from "react-spinners";

const Signupfamily = () => {

    const dispatch = useDispatch();
    const data = useSelector((state) => state.rootReducer.signUpfamily.data);
    const isLoading = useSelector((state) => state.rootReducer.signUpfamily.loading);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [mobileNumber, setmobileNumber] = useState('');
    const [experienceOfYears] = useState('');
    const [country, setCountry] = useState('+91');

    const handleOnChange = (value, country) => {
        console.log(value)
        //setMobileNumber(va);
        setCountry(value);
    };

    const handleSignup = () => {
        const payload = {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            countryCode: country,
            mobileNumber: mobileNumber,
            referralCode: "",
            dateOfBirth: "",
            experienceOfYears: experienceOfYears,
            country: "sds",
            postalCode: "2342432",
            address: "sdf",
            latitude: "23.23232",
            longitude: "45.34343",
            type: 2,
        }

        try {
            dispatch(familyUser(payload));
            // Handle success, e.g., navigate to another screen
        } catch (error) {
            // Handle error, e.g., display an error message
            console.error('signup failed:', error);
        }
    };

    const navigate = useNavigate();

    const navigateToAnotherScreen = () => {
        navigate('/otp');
    }

    useEffect(() => {
        console.log("data signup family ===>", data)
        if (data != null && data.status === 1) {
            navigateToAnotherScreen();
        }
    }, [data])




    // const googleLogin = useGoogleLogin({
    //     onSuccess: (tokenResponse) => {
    //         console.log('Google login successful', tokenResponse);
    //         // You can now use the tokenResponse to authenticate the user in your app
    //     },
    //     onError: () => {
    //         console.error('Google login failed');
    //         // Handle login errors here
    //     },
    //     flow: 'auth-code', // Use 'auth-code' for the authorization code flow
    // });



    // const handleSuccess = (credentialResponse) => {
    //     // If you are using the authorization code flow, you will receive a code to be exchanged for an access token
    //     const authorizationCode = credentialResponse.code;

    //     // Send the authorization code to your backend server
    //     fetch('/api/auth/google', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ code: authorizationCode }),
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             // Handle the response from your backend server
    //             console.log('Login successful, backend response:', data);
    //         })
    //         .catch(error => {
    //             // Handle errors in communicating with your backend server
    //             console.error('Error exchanging authorization code:', error);
    //         });
    // };

    // const handleError = (errorResponse) => {
    //     console.error('Google login failed', errorResponse);
    // };


    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="container py-5">

                        <a href="#" className="custom-logo d-block login-logo mx-auto">
                            <img className="mw-100" src={logo} alt="logo" />
                        </a>

                        <div className="row">
                            <div className="col-lg-6 offset-lg-3 offset-sm-1 col-sm-10">
                                <div className="login-container">
                                    <form className="login-form" appearance="outline">
                                        <h3>Welcome to User</h3>
                                        <p>Please Signup </p>

                                        <div className='input-group input_width'>
                                            <input type="text" placeholder="FirstName" value={firstName} onChange={(e) => setfirstName(e.target.value)} style={{ width: "49%" }} />
                                            <input type="text" placeholder="LastName" value={lastName} onChange={(e) => setlastName(e.target.value)} style={{ width: "49%" }} />
                                        </div>

                                        <div className='btn_flex mb-3'>
                                            <PhoneInput
                                                inputClass={"inputt-ph"}
                                                containerStyle={{}}
                                                searchClass="search-class"
                                                disableSearchIcon={false}
                                                enableTerritories
                                                countryCodeEditable={false}
                                                placeholder='Phone Number'
                                                buttonStyle={{ width: '47px' }}
                                                dropdownStyle={{ height: '150px' }}
                                                enableSearch={true}
                                                value={country}
                                                onChange={handleOnChange}
                                                inputProps={{
                                                    readOnly: true, // This makes the input read-only
                                                }}
                                                enableAreaCodeStretch />
                                            <input type='number' className='mobilenumber' value={mobileNumber} onChange={(e) => setmobileNumber(e.target.value)} />
                                        </div>

                                        <div className='input-group'>
                                            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>

                                        <div className="input-group">
                                            <input
                                                type='password'
                                                id="password"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>

                                        <div className='input-group'>
                                            <input type="text" placeholder="Referral Code"
                                            />
                                        </div>

                                        <Button type='button' onClick={() => handleSignup()}>
                                            {isLoading ? (
                                                <ClipLoader color="#fff" />
                                            ) : (
                                                "Register"
                                            )}
                                        </Button>

                                        <div className='bottom-text'>
                                            <div className="mt-3 bottom_link">Im a new user.
                                                <div className='flex_link'>
                                                    <p className='link_color' onClick={() => { scrollToTop(); navigate('/signupnanny') }}> {" "}REGISTER AS NANNY</p>
                                                    <span className='mx-1'> / </span>
                                                    <p className='link_color' onClick={() => { scrollToTop(); navigate('/signupfamily') }}>REGISTER AS FAMILY</p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <GoogleLogin
                                            onSuccess={handleSuccess}
                                            onError={handleError}
                                            useOneTap
                                            flow="auth-code"
                                        /> */}

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Signupfamily
