import React, { useState, useRef, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NannyEditprofile, clearData } from '../../store/apiSlice/NeditProfileSlice';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { uploadFile } from '../../store/apiSlice/uploadFileSlice';
import profile_icon from '../../assets/img/profile_icon.png';
import { getNannyProfile } from '../../store/apiSlice/NannyprofileSlice';
import Select from 'react-select';


const NeditProfile = () => {

    const dispatch = useDispatch();
    const NannyEditData = useSelector((state) => state.rootReducer.NannyEditprofileReducer.data);
    const profileData = useSelector((state) => state.rootReducer.nannyProfileReducer.data);
    const uploadFileData = useSelector((state) => state.rootReducer.uploadFileReducer.data);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [date, setDate] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [education, setEducation] = useState('');
    const [country, setCountry] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [description, setDescription] = useState('');
    const [experienceOfYears, setExperienceOfYears] = useState('');
    const [familyBackground, setFamilyBackground] = useState('');
    const [isCarOwn, setIsCarOwn] = useState(1);
    const [isGoodWithDogSitting, setIsGoodWithDogSitting] = useState(1);
    const [isDrivingLicense, setIsDrivingLicense] = useState(1);
    const [isSmoker, setIsSmoker] = useState(1);
    const [isCleaningUp, setIsCleaningUp] = useState(1);
    const [disabled, setDisabled] = useState(false)


    const updateProfile = () => {

        if (disabled) {
            const payload = {
                firstName: firstName,
                lastName: lastName,
                dateOfBirth: date,
                address: profileData.data.address,
                profileImage: profileImage,
                pinCode: pinCode,
                latitude: profileData.data.latitude,
                longitude: profileData.data.longitude,
                countryCode: profileData.data.countryCode,
                description: description,
                experienceOfYears: experienceOfYears,
                familyBackground: familyBackground,
                isCarOwn: isCarOwn,
                isDrivingLicense: isDrivingLicense,
                isGoodWithDogSitting: isGoodWithDogSitting,
                isSmoker: isSmoker,
                isCleaningUp: isCleaningUp,
                languages: languages,
            }

            try {
                console.log("NannyEditprofile  ===>", payload)

                dispatch(NannyEditprofile(payload));

                // Handle success, e.g., navigate to another screen
            } catch (error) {
                // Handle error, e.g., display an error message
                console.error('NannyEditprofile failed:', error);
            }

        } else {
            setDisabled(true)
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        console.log(" Data", NannyEditData)
        if (NannyEditData != null && NannyEditData.status === 1) {
            setDisabled(false);
            dispatch(getNannyProfile());
            dispatch(clearData())
            alert(NannyEditData.message);

        }
        else if (NannyEditData != null) {
            alert(NannyEditData.message)
        }
    }, [NannyEditData])


    useEffect(() => {
        console.log("Profile data ===> ", profileData)
        if (profileData != null && profileData.status === 1) {
            setFirstName(profileData.data.firstName)
            setLastName(profileData.data.lastName)
            setDate(profileData.data.dateOfBirth)
            setEmail(profileData.data.email)
            setEducation(profileData.data.education)
            setPinCode(profileData.data.pinCode)
            setCountry(profileData.data.countryCode);
            setMobileNumber(profileData.data.mobileNumber)
            setProfileImage(profileData.data.profileImage);
            setDescription(profileData.data.description);
            setExperienceOfYears(profileData.data.experienceOfYears);
            setFamilyBackground(profileData.data.familyBackground);
            setIsCarOwn(profileData.data.isCarOwn);
            setIsGoodWithDogSitting(profileData.data.IsGoodWithDogSitting);
            setIsDrivingLicense(profileData.data.isDrivingLicense);
            setIsSmoker(profileData.data.isSmoker);
            setIsCleaningUp(profileData.data.isCleaningUp);
            setLanguages(profileData.data.languages);
        }

    }, [profileData])

    const handleOnChange = (value, country) => {
        console.log(country.dialCode)
        console.log(value)
        //setMobileNumber(va);
        setCountry(country.dialCode);
    };

    useEffect(() => {
        console.log("uploading ==>", uploadFileData)
        if (uploadFileData != null) {
            setProfileImage(uploadFileData.Location);
        }
    }, [uploadFileData]);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        dispatch(uploadFile(file));
    };

    useEffect(() => {
        console.log("IS CAR OWN ===> ", isCarOwn)
    }, [isCarOwn])


    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [description]);

    const handleChange = (event) => {
        setDescription(event.target.value);
    };

    const [languages, setLanguages] = useState([{ value: 'English', label: 'English' }]);

    const options = [
        { value: 'English', label: 'English' },
        { value: 'Spanish', label: 'Spanish' },
        { value: 'Arabic', label: 'Arabic' },
    ];

    const handleSelectChange = (languages) => {
        setLanguages(languages);
    };

    return (
        <>
            <div className="row" >
                <div className="col-12">
                    {profileData != null &&
                        <div className="row">
                            <div className="col-12 mb-4 edit_photo">
                                <div className='edit_flex'>
                                    <div className="upload-input upload_input_text">
                                        <label htmlFor="upload"><span><img src={profileData != null && profileImage != "" ? profileImage : profile_icon} alt="card" /></span>
                                            <span><input type="file" id="upload" style={{ display: "none" }} onChange={handleFileUpload} /></span>
                                        </label>
                                        <label className="btn_upload ms-3" style={{ color: "#89d6ce" }} htmlFor="upload">{disabled ? "Change Porfile Image" : ""}</label>
                                    </div>
                                    <Button type='button' color="primary" className="profile_button save " onClick={() => updateProfile()}>
                                        {disabled ? "Save" : "Edit Profile"}
                                    </Button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>First Name</Form.Label>
                                    <div className="input-group mb-0">
                                        <input type="text" disabled={!disabled} placeholder="First Name" value={firstName} onChange={(val) => setFirstName(val.target.value)} />
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>Last Name</Form.Label>
                                    <div className="input-group mb-0">
                                        <input type="text" disabled={!disabled} placeholder="Last Name" value={lastName} onChange={(val) => setLastName(val.target.value)} />
                                    </div>
                                </form>
                            </div>
                            <div className='col-md-6'>
                                <form>
                                    <div className='main_input'>
                                        <div className="input_field_flex ">
                                            <Form.Label>Phone Number</Form.Label>
                                            <div className='btn_flex'>
                                                <PhoneInput
                                                    disabled={true}
                                                    inputClass={"inputt-ph"}
                                                    containerStyle={{}}
                                                    searchClass="search-class"
                                                    disableSearchIcon={false}
                                                    enableTerritories
                                                    countryCodeEditable={false}
                                                    placeholder='Phone Number'
                                                    buttonStyle={{ width: '47px' }}
                                                    dropdownStyle={{ height: '150px' }}
                                                    enableSearch={true}
                                                    value={country}
                                                    onChange={handleOnChange}
                                                    readOnly
                                                />
                                                <input type='number' disabled={true} className='mobilenumber' value={mobileNumber} onChange={(val) => setMobileNumber(val.target.value)} />
                                            </div>
                                            {/* <div className='btn_flex'>
                                                <PhoneInput
                                                    inputClass={"inputt-ph"}
                                                    containerStyle={{}}
                                                    searchClass="search-class"
                                                    disableSearchIcon={false}
                                                    enableTerritories
                                                    countryCodeEditable={true}
                                                    placeholder='Phone Number'
                                                    buttonStyle={{ width: '47px' }}
                                                    dropdownStyle={{ height: '150px' }}
                                                    country={"in"}
                                                    enableSearch={true}
                                                    value={mobileNumber}
                                                //onChange={(val) => setMobileNumber(val.target.value)}
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>Email</Form.Label>
                                    <div className="input-group mb-0">
                                        <input type="text" disabled={true} placeholder="Enter Email" value={email} onChange={(val) => setEmail(val.target.value)} />
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>Postal Code</Form.Label>
                                    <div className="input-group mb-0">
                                        <input type="text" disabled={!disabled} placeholder="Postal Code" value={pinCode} onChange={(val) => setPinCode(val.target.value)} />
                                    </div>
                                </form>
                            </div>

                            <div className="col-md-6">
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>Date of Birth</Form.Label>
                                    <div className="input-group mb-0">
                                        <input type='date' disabled={!disabled} value={date} onChange={(val) => setDate(val.target.value)} />
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>Education</Form.Label>
                                    <div className="input-group mb-0">
                                        <input type="text" disabled={!disabled} placeholder="Education" value={education} onChange={(val) => setEducation(val.target.value)} />
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>Family Background</Form.Label>
                                    <div className="input-group mb-0">
                                        <input type="text" disabled={!disabled} placeholder="Family Background" value={familyBackground} onChange={(val) => setFamilyBackground(val.target.value)} />
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>Number of Years Experience</Form.Label>
                                    <div className="input-group mb-0">
                                        <input type="number" disabled={!disabled} value={experienceOfYears} placeholder="Number of Years Experience" />
                                    </div>
                                </form>
                            </div>
                            <div className='col-md-6'>
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>Language</Form.Label>
                                    <div className="input-group mb-0">
                                        <Select
                                            isMulti
                                            disabled={!disabled}
                                            name="options"
                                            options={options}
                                            value={languages}
                                            onChange={handleSelectChange}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                        {/* <Form.Select disabled={!disabled} className='d-flex' aria-label="Default select example" value={languages} onChange={(e) => setLanguages(e.target.value)}>
                                            <option value="1">English</option>
                                            <option value="0">Spanish</option>
                                        </Form.Select> */}
                                    </div>
                                </form>
                            </div>
                            <div className='col-md-6'>
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>Are you good with Dog Sitting</Form.Label>
                                    <div className="input-group mb-0">
                                        <Form.Select disabled={!disabled} aria-label="Default select example" value={isGoodWithDogSitting} onChange={(e) => setIsGoodWithDogSitting(e.target.value)}>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Form.Select>
                                    </div>
                                </form>
                            </div>
                            <div className='col-md-6'>
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>Do you smoke</Form.Label>
                                    <div className="input-group mb-0">
                                        <Form.Select disabled={!disabled} aria-label="Default select example" value={isSmoker} onChange={(e) => setIsSmoker(e.target.value)}>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Form.Select>
                                    </div>
                                </form>
                            </div>
                            <div className='col-md-6'>
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>Do You have driving licence</Form.Label>
                                    <div className="input-group mb-0">
                                        <Form.Select disabled={!disabled} aria-label="Default select example" value={isDrivingLicense} onChange={(e) => setIsDrivingLicense(e.target.value)}>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Form.Select>
                                    </div>
                                </form>
                            </div>
                            <div className='col-md-6'>
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>Do you own a car</Form.Label>
                                    <div className="input-group mb-0">
                                        <Form.Select onChange={(e) => setIsCarOwn(e.target.value)} disabled={!disabled} aria-label="Default select example" value={isCarOwn}>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Form.Select>
                                    </div>
                                </form>
                            </div>
                            <div className='col-md-6'>
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>Do You mind cleaning up after the child and light tidy up?</Form.Label>
                                    <div className="input-group mb-0">
                                        <Form.Select disabled={!disabled} aria-label="Default select example" value={isCleaningUp} onChange={(e) => setIsCleaningUp(e.target.value)}>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Form.Select>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <form className="w-100 mb-4" appearance="outline">
                                    <Form.Label>About</Form.Label>
                                    <div className="input-group mb-0">
                                        <textarea
                                            ref={textareaRef}
                                            disabled={!disabled}
                                            value={description}
                                            onChange={handleChange}
                                            rows={1}
                                            style={{ width: '100%', boxSizing: 'border-box', overflow: 'hidden', resize: 'none', padding: 10, borderRadius: 8, outline: 'none', borderColor: "#ddd" }}
                                            placeholder="Type something..."
                                        />
                                    </div>
                                </form>
                            </div>
                        </div >
                    }
                </div >
            </div >
        </>
    )
}

export default NeditProfile
