import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";

const CheckoutForm = ({ price }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    const token = localStorage.getItem("Token");
    const headers = {
      Authorization: token,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };
    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    await fetch(
      `https://dev-api-nanny.virtualittechnology.com/v1/user/getStripeIntent?price=${price}`,
      {
        method: "GET",
        headers: headers,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("DATA ===>", data.data.client_secret);
        setClientSecret(data.data.client_secret);
        confPayment(data.data.client_secret);
      });
  };

  const confPayment = async (clientSecret) => {
    console.log("Client Secret ===>", clientSecret);
    if (clientSecret.length > 0) {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: "https://dev-api-nanny.virtualittechnology.com/redirect",
        },
      });

      if (error) {
        setErrorMessage(error.message);
      } else {
        console.log("Payment Intent ===> ", paymentIntent);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement className="custom-input" />
      <div className="pay_button">
        <button type="submit" disabled={!stripe || !elements}>
          Pay
        </button>
      </div>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const stripePromise = loadStripe("pk_test_lTQlClwcnwQjs3Am2NSFga8i");

const StipePayment = () => {
  const location = useLocation();
  const {
    address,
    date,
    time,
    counter,
    divs,
    latitude,
    longitude,
    categoryId,
    profileImage,
    price,
    firstName,
    lastName,
    nannyId,
  } = location.state;
  const options = {
    mode: "payment",
    amount: price * 1000,
    currency: "usd",
    setup_future_usage: "off_session",
    payment_method_types: ["card"],
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <div className="container">
      <div className="stripe_card_center">
        <div className="stripe_width">
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm price={price * 1000} />
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default StipePayment;
