import React, { useEffect, useState } from 'react';
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Forward, Leaderboard, MenuOutlined, Notifications } from "@mui/icons-material";
import logo from '../../assets/img/logo_nav.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearData } from '../../store/apiSlice/LoginSlice';
import PersonIcon from '@mui/icons-material/Person';

const HeaderNanny = () => {

    const [scrolled, setScrolled] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {

        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const [openModal2, setOpenModal2] = useState(false);

    const onOpenModal2 = () => {
        setOpenModal2(true);
    };

    const onCloseModal2 = () => {
        setOpenModal2(false);
    };

    const onLogoutClick = () => {

        localStorage.clear()
        dispatch(clearData())

        console.log("Logout")
        navigate('/login');
        onCloseModal2()
    }


    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);


    return (
        <>
            <div className={`header main-head ${scrolled ? "scrolled" : ""}`}>
                <div className="coustom_container">
                    {localStorage.getItem("Token") == null ?
                        (<div className="inner">
                            <div className="logo">
                                <Link to="/" onClick={scrollToTop}>
                                    <img className="mw-100" src={logo} alt="logo" style={{ width: "160px" }} />
                                </Link>
                            </div>
                            <div className="navigation">
                                <ul>
                                    <li>
                                        <Link to="/" onClick={scrollToTop}>Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/about" onClick={scrollToTop}>About</Link>
                                    </li>
                                    <li>
                                        <Link to="/services" onClick={scrollToTop}>Services</Link>
                                    </li>
                                    <li>
                                        <Link to="/download" onClick={scrollToTop}>Download</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/events" onClick={scrollToTop}>Events</Link>
                                    </li>
                                    <li>
                                        <Link to="/tutorings" onClick={scrollToTop}>Tutorings</Link>
                                    </li> */}
                                    <li>
                                        <Link to="/contact" onClick={scrollToTop}>Contact</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="btns">
                                <div className="btn">
                                    <Link className="main-button" to="/login" onClick={scrollToTop}>
                                        Login
                                    </Link>
                                </div>
                                <div className="btn">
                                    <Link className="main-button" to="/signupnanny" onClick={scrollToTop}>
                                        Sign up
                                    </Link>
                                </div>
                            </div>
                        </div>)
                        :
                        (<div className="inner">
                            <div className="logo logo_header">
                                <Link to="/" onClick={scrollToTop}>
                                    <img className="mw-100 logo_width" src={logo} alt="logo" />
                                </Link>
                            </div>
                            <div className="navigation">
                                <ul>
                                    <li>
                                        {/*<Link to="/nannycategories">Home</Link>
                                        </li>
                                        <li>
                                            <Link to="/Nannybooking">Booking</Link>
                                        </li>
                                        <li>
                                            <Link to="/availability">Availability</Link>*/}
                                    </li>
                                </ul>
                            </div>
                            <div className="btns">
                                <div className="btn logout_btn_header">
                                    <button type='button' className="main-button " onClick={() => onLogoutClick()}>
                                        Logout
                                    </button>
                                </div>
                                <div className='notifications'>
                                    <ul className='nav'>
                                        <li className='list_item'>
                                            <Link to="#" className="main-button notification_btn_header"> <Notifications /></Link>

                                            <div className="hover_content_public">
                                                <div className='drop_downcont'>
                                                    <ul>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <Link className='hover_a'>
                                                                    <div className="item_flex">
                                                                        <div className="icon_gap">
                                                                            <Leaderboard />
                                                                            <div>
                                                                                <span>LeaderBoard</span>
                                                                                <p>Join in on our giveaways and promotions</p>
                                                                            </div>
                                                                        </div>
                                                                        <Forward className='forward_icon' />
                                                                    </div>

                                                                </Link>
                                                            </div>
                                                            <div className='col-lg-12'>
                                                                <Link className='hover_a'>
                                                                    <div className="item_flex">
                                                                        <div className="icon_gap">
                                                                            <Leaderboard />
                                                                            <div>
                                                                                <span>Reward Center</span>
                                                                                <p>Complete tasks and get rewards in one click</p>
                                                                            </div>
                                                                        </div>
                                                                        <Forward className='forward_icon' />
                                                                    </div>
                                                                </Link>
                                                            </div>


                                                            <div className="col-lg-12">
                                                                <Link className='hover_a'>
                                                                    <div className="item_flex">
                                                                        <div className="icon_gap">
                                                                            <Leaderboard />
                                                                            <div>
                                                                                <span>Promotions</span>
                                                                                <p>Complete tasks and get rewards in one click</p>
                                                                            </div>
                                                                        </div>
                                                                        <Forward className='forward_icon' />
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className='btn profile profile_btn_header' onClick={scrollToTop}>
                                    <Link className='main-button ' to="/profilenanny"><PersonIcon /></Link>
                                </div>

                            </div>
                        </div>
                        )
                    }
                </div>
            </div>

            <div className="mob-header">
                <div className="inner">
                    <div className="logo">
                        <Link to="/"> <img className="mw-100" onClick={scrollToTop} src={logo} alt="logo" style={{ width: "160px" }} /></Link>
                    </div>

                    <div className="">
                        <MenuOutlined onClick={onOpenModal2} />
                    </div>
                </div>
            </div>

            {/* modal Mobile header menu*/}
            <Modal
                open={openModal2}
                onClose={onCloseModal2}
                classNames={{
                    modal: "P2P-ID__data",
                    modalAnimationIn: "customEnterModalAnimation",
                    modalAnimationOut: "customLeaveModalAnimation",
                }}
                center
            >
                {localStorage.getItem("Token") == null ?
                    (
                        <div className="main-sec">
                            <div className="inner-sec">
                                <ul className="menu-list2">
                                    <Link to="/" className="head_sec_top" onClick={onCloseModal2}>Home</Link>
                                    <Link to="/about" onClick={onCloseModal2}>About</Link>
                                    <Link to="/services" onClick={onCloseModal2}>Services</Link>
                                    <Link to="/download" onClick={onCloseModal2}>Download</Link>
                                    {/* <Link to="/events" onClick={onCloseModal2}>Events</Link>
                                    <Link to="/tutorings" onClick={onCloseModal2}>Tutorings</Link> */}
                                    <Link to="/contact" onClick={onCloseModal2}>Contact</Link>
                                </ul>
                            </div>

                            <div className="btn">
                                <span className="home_span_flex me-3"><Link to="/login" className="main-button login_btns" onClick={onCloseModal2}>Login</Link></span>
                                <span className="home_span_flex"><Link to="/signupnanny" className="second-button login_btns" onClick={onCloseModal2}>Sign up</Link></span>
                            </div>
                        </div>) : (
                        <div className="main-sec">
                            <div className="inner-sec">
                                <ul className="menu-list2">
                                    <Link to="/" onClick={onCloseModal2}>Home</Link>
                                    {/* <Link to="/about" onClick={onCloseModal2}>About</Link>
                                    <Link to="/services" onClick={onCloseModal2}>Services</Link>
                                    <Link to="/events" onClick={onCloseModal2}>Events</Link>
                                    <Link to="/tutorings" onClick={onCloseModal2}>Tutorings</Link>
                                    <Link to="/contact" onClick={onCloseModal2}>Contact</Link> */}
                                </ul>
                            </div>
                            <div className='d-flex justify-content-center align-items-center' style={{ gap: 10 }}>
                                <span className="span_flex">
                                    <button type='button' className="main-button logout_btn" onClick={() => onLogoutClick()}>
                                        Logout
                                    </button>
                                </span>

                                <div className='notifications'>
                                    <ul className='nav'>
                                        <li className='list_item'>
                                            <Link to="#" className="main-button "> <Notifications /></Link>

                                            <div className="hover_content_public">
                                                <div className='drop_downcont'>
                                                    <ul>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <Link className='hover_a'>
                                                                    <div className="item_flex">
                                                                        <div className="icon_gap">
                                                                            <Leaderboard />
                                                                            <div>
                                                                                <span>LeaderBoard</span>
                                                                                <p>Join in on our giveaways and promotions</p>
                                                                            </div>
                                                                        </div>
                                                                        <Forward className='forward_icon' />
                                                                    </div>

                                                                </Link>
                                                            </div>
                                                            <div className='col-lg-12'>
                                                                <Link className='hover_a'>
                                                                    <div className="item_flex">
                                                                        <div className="icon_gap">
                                                                            <Leaderboard />
                                                                            <div>
                                                                                <span>Reward Center</span>
                                                                                <p>Complete tasks and get rewards in one click</p>
                                                                            </div>
                                                                        </div>
                                                                        <Forward className='forward_icon' />
                                                                    </div>
                                                                </Link>
                                                            </div>


                                                            <div className="col-lg-12">
                                                                <Link className='hover_a'>
                                                                    <div className="item_flex">
                                                                        <div className="icon_gap">
                                                                            <Leaderboard />
                                                                            <div>
                                                                                <span>Promotions</span>
                                                                                <p>Complete tasks and get rewards in one click</p>
                                                                            </div>
                                                                        </div>
                                                                        <Forward className='forward_icon' />
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className='span_flex'>
                                    <button type='button' className='main-button logout_btn' onClick={() => navigate("/profilenanny")}><PersonIcon /></button>
                                </div>
                            </div>
                        </div>
                    )}
            </Modal>

        </>
    )
}

export default HeaderNanny
